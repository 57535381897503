import {
    ExperienceFiltersExperiment,
    ExperienceFiltersExperimentValues,
    EXPERIENCES_MAPPING_SEARCH,
    useServerEnrolledStatsigExperiment,
} from '@eventbrite/discover-utils';
import { Checkbox } from '@eventbrite/marmalade';
import { logEvent } from '@eventbrite/statsig';
import React from 'react';
import {
    constructFilterItemKey,
    EXPERIENCES_FILTER,
    FACETED_SEARCH_DROPDOWN_FILTER_PROP_MAP,
    FilterItem,
    FilterProps,
    FilterSection,
    RadioType,
    STATSIG_EXPERIENCE_FILTER_ClICK,
} from '../../../';

export const ExperiencesFilters: React.FunctionComponent<FilterProps> = (
    props,
) => {
    const shouldExperienceFiltersBeEnabled = props.locale === 'en_US';
    const ExperienceFiltersExperimentVariant =
        useServerEnrolledStatsigExperiment<ExperienceFiltersExperimentValues>({
            name: ExperienceFiltersExperiment.name,
            paramName: ExperienceFiltersExperiment.paramName,
            defaultValue: ExperienceFiltersExperiment.values.control,
            enabled: shouldExperienceFiltersBeEnabled,
        }).variant;

    const _logExperienceFilterUpdateToStatsig = (
        appliedExperienceFilters?: string[],
        selectedExperiences?: string[],
    ) => {
        if (!selectedExperiences || !appliedExperienceFilters) {
            return;
        }
        let selectedExperience = '';
        let isApplyingFilter = '';
        if (selectedExperiences.length > appliedExperienceFilters.length) {
            // the user has selected a new experience filter
            selectedExperience =
                selectedExperiences[selectedExperiences.length - 1];
            isApplyingFilter = 'true';
        } else {
            // the user is removing an experience filter
            selectedExperience = appliedExperienceFilters.filter(
                (experience: string) =>
                    !selectedExperiences.includes(experience),
            )[0];
            isApplyingFilter = 'false';
        }
        logEvent(STATSIG_EXPERIENCE_FILTER_ClICK, undefined, {
            experience: selectedExperience,
            isApplyingFilter,
            location: 'filterPanel',
        });
    };

    if (
        !shouldExperienceFiltersBeEnabled ||
        ExperienceFiltersExperimentVariant === 'hide'
    ) {
        return null;
    }

    return (
        <FilterSection
            filters={{
                ...EXPERIENCES_MAPPING_SEARCH,
            }}
            onOptionChange={(value: string[]) => {
                props.onFilterChange?.(EXPERIENCES_FILTER, value);
                _logExperienceFilterUpdateToStatsig(
                    props.selectedFilters.experiences,
                    value,
                );
            }}
            title={
                FACETED_SEARCH_DROPDOWN_FILTER_PROP_MAP[EXPERIENCES_FILTER]
                    .label
            }
            activeFilterOption={props.selectedFilters?.[EXPERIENCES_FILTER]}
            shouldScrollIntoView={props.filterTypeToShow === EXPERIENCES_FILTER}
            isMobile={props.isMobile}
        >
            {(filterItem: RadioType) => {
                return (
                    <FilterItem
                        ChoiceComponent={Checkbox}
                        key={constructFilterItemKey(
                            'experiences',
                            filterItem.value,
                            props.isMobile,
                        )}
                        value={filterItem.value}
                        display={filterItem.display}
                    />
                );
            }}
        </FilterSection>
    );
};
