import React from 'react';
import {
    CURRENCY_FILTER,
    FACETED_SEARCH_DROPDOWN_FILTER_PROP_MAP,
    FORMATTED_CURRENCIES,
} from '../../../constants';
import {
    constructFilterItemKey,
    FilterItem,
    FilterProps,
    FilterSection,
    RadioType,
} from '../FilterPanel';

export const CurrencyFilters = (props: FilterProps) => {
    return (
        <FilterSection
            filters={{
                ...FORMATTED_CURRENCIES,
            }}
            onOptionChange={(value: any) => {
                props.onFilterChange?.(CURRENCY_FILTER, value);
            }}
            activeFilterOption={props.selectedFilters?.[CURRENCY_FILTER]}
            shouldScrollIntoView={props.filterTypeToShow === CURRENCY_FILTER}
            title={
                FACETED_SEARCH_DROPDOWN_FILTER_PROP_MAP[CURRENCY_FILTER].label
            }
            isMobile={props.isMobile}
        >
            {(filterItem: RadioType) => {
                return (
                    <FilterItem
                        key={constructFilterItemKey(
                            'currency',
                            filterItem.value,
                            props.isMobile,
                        )}
                        value={filterItem.value}
                        display={filterItem.display}
                    />
                );
            }}
        </FilterSection>
    );
};
