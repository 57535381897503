import { gettext } from '@eventbrite/i18n';
import { Button } from '@eventbrite/marmalade';
import React, { useContext } from 'react';
import { ReadMoreContext } from './context';
import './ReadMore.scss';
import { ReadMoreToggleProps } from './types';

const handleOnClick = (state: boolean, callback?: Function) => {
    callback?.(state);
};

const defaultLabels = {
    labels: {
        hide: gettext('View less'),
        show: gettext('View more'),
    },
};

export const ReadMoreToggle: React.FunctionComponent<ReadMoreToggleProps> = ({
    labels = defaultLabels.labels,
    toggleClass,
}) => {
    const { isExpanded, setExpanded, isClamped, ariaLabel } =
        useContext(ReadMoreContext);

    if (!isClamped) {
        return null;
    }

    return (
        <div
            className={`read-more__toggle ${toggleClass ? toggleClass : ''}`}
            id={ariaLabel}
        >
            <Button
                aria-controls={ariaLabel}
                data-testid="read-more-toggle"
                aria-labelledby={gettext('View more').toString()}
                onClick={() => {
                    handleOnClick(!isExpanded, setExpanded);
                }}
                variant="ghost"
            >
                <span aria-label={gettext('View more').toString()}>
                    {isExpanded ? labels?.hide : labels?.show}
                </span>
            </Button>
        </div>
    );
};
