import React from 'react';
import {
    EVENTBRITE_FORMAT_MAPPING_SEARCH,
    FACETED_SEARCH_DROPDOWN_FILTER_PROP_MAP,
    FORMAT_FILTER,
} from '../../../constants';
import {
    constructFilterItemKey,
    FilterItem,
    FilterProps,
    FilterSection,
    RadioType,
} from '../FilterPanel';

export const FormatFilters = (props: FilterProps) => {
    return (
        <FilterSection
            filters={{
                ...EVENTBRITE_FORMAT_MAPPING_SEARCH,
            }}
            onOptionChange={(value: any) => {
                props.onFilterChange?.(FORMAT_FILTER, value);
            }}
            title={FACETED_SEARCH_DROPDOWN_FILTER_PROP_MAP[FORMAT_FILTER].label}
            activeFilterOption={props.selectedFilters?.[FORMAT_FILTER]}
            shouldScrollIntoView={props.filterTypeToShow === FORMAT_FILTER}
            isMobile={props.isMobile}
        >
            {(filterItem: RadioType) => {
                return (
                    <FilterItem
                        key={constructFilterItemKey(
                            'format',
                            filterItem.value,
                            props.isMobile,
                        )}
                        value={filterItem.value}
                        display={filterItem.display}
                    />
                );
            }}
        </FilterSection>
    );
};
