import classNames from 'classnames';
import React, { useContext } from 'react';
import { ReadMoreContext } from './context';
import {
    PreviewContentChildType,
    PreviewContentLineType,
    PreviewContentNumberConfig,
    PreviewContentPixelConfig,
    PreviewContentPixelType,
    ReadMoreContentProps,
} from './types';
import { childClampState } from './utils';

const getStyleDefs = (
    styleConfig: PreviewContentNumberConfig | PreviewContentPixelConfig,
) => {
    const notObject =
        typeof styleConfig === 'number' || typeof styleConfig === 'string';
    return {
        '--mobile-val': notObject ? styleConfig : styleConfig.mobile,
        '--tablet-val': notObject ? styleConfig : styleConfig.tablet,
        '--desktop-val': notObject ? styleConfig : styleConfig.desktop,
    } as React.CSSProperties;
};

const ReadMoreContentCSSClamp: React.FunctionComponent<
    PreviewContentLineType | PreviewContentPixelType
> = (props) => {
    const previewType = props.previewContentType;
    const { isExpanded, setIsClamped, ariaLabel } = useContext(ReadMoreContext);
    const className = classNames('read-more--content', {
        'read-more--content__clamped': !isExpanded,
        'read-more--content__clamped--line-count': previewType === 'line',
        'read-more--content__clamped--pixel-count': previewType === 'pixel',
    });

    //Determine if the children are clamped so other
    //elements may respond to that data.
    const isClampedRef = React.useCallback(
        (element) => {
            if (element) {
                const elementIsClampingContent =
                    element.scrollHeight > element.clientHeight;
                setIsClamped?.(elementIsClampingContent);
            }
        },
        [setIsClamped],
    );

    const isLineCount = previewType === 'line';
    const styleConfig = isLineCount
        ? props.previewLineCount
        : props.previewPixelCount;

    const styleDefs = getStyleDefs(styleConfig);

    return (
        <div
            ref={isClampedRef}
            id={ariaLabel}
            data-testid="read-more-content"
            className={className}
            style={{ ...styleDefs }}
        >
            {props.children}
        </div>
    );
};

const ReadMoreContentChildClamp: React.FunctionComponent<
    PreviewContentChildType
> = (props) => {
    const { isExpanded, setIsClamped, isClamped } = useContext(ReadMoreContext);

    const shouldClamp = childClampState(
        props.previewChildCount,
        React.Children.toArray(props.children).length,
    );

    if (shouldClamp && !isClamped) {
        setIsClamped?.(true);
    } else if (!shouldClamp && isClamped) {
        setIsClamped?.(false);
    }

    return (
        <>
            {React.Children.map(props.children, (child, index) => {
                if (isExpanded) {
                    return child;
                }

                if (!isExpanded && index < props.previewChildCount) {
                    return child;
                } else {
                    return null;
                }
            })}
        </>
    );
};

export const ReadMoreContent: React.FunctionComponent<ReadMoreContentProps> = (
    props,
) => {
    if (props.previewContentType === 'children') {
        return (
            <ReadMoreContentChildClamp {...props}>
                {props.children}
            </ReadMoreContentChildClamp>
        );
    }

    return (
        <ReadMoreContentCSSClamp {...props}>
            {props.children}
        </ReadMoreContentCSSClamp>
    );
};
