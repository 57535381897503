import {
    childClampState,
    ReadMore,
    ReadMoreContent,
    ReadMoreToggle,
} from '@eventbrite/discover-utils';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import { ChoiceListBase, ChoiceListItem, Radio } from '@eventbrite/marmalade';
import React from 'react';
import './FilterSection.scss';
import { useShouldScrollIntoView } from './hooks';
import { FilterItemProps, FilterSectionProps } from './index';

const PREVIEW_CHILD_COUNT = 4;

export const getRadioValues = (filters: {
    [key: string]: GenericLazyString;
}) => {
    const entries = Object.entries(filters);
    return entries.map(([key, label]) => ({
        display: label,
        value: key,
    }));
};

export const FilterItem: React.FunctionComponent<FilterItemProps> = ({
    ChoiceComponent = Radio,
    value,
    display,
    children,
}) => {
    return (
        <>
            <ChoiceListItem value={value} label={display}>
                <ChoiceComponent data-testid={`filter-display-${display}`} />
            </ChoiceListItem>
            {children}
        </>
    );
};

export const FilterSection = ({
    filters,
    onOptionChange,
    title,
    activeFilterOption,
    isMobile,
    defaultActiveFilterOption = '',
    children,
    shouldScrollIntoView,
    tokens = {
        '--FilterSectionMargin': '3rem 0 0 0',
        '--FieldSetLegendPadding': '0 0 0.75rem 0',
        '--LiPadding': '0.75rem 0 0 0',
    },
}: FilterSectionProps) => {
    const node = useShouldScrollIntoView(!!shouldScrollIntoView);
    let radioValuesArray;

    if (Array.isArray(filters)) {
        radioValuesArray = filters;
    } else {
        radioValuesArray = getRadioValues(filters);
    }

    const handleOptionChange = (value: string) => {
        if (value) {
            onOptionChange?.(value);
        }
    };

    const radioValueElements = radioValuesArray.map((radioValue) =>
        children(radioValue),
    );

    const readMoreA11yLabel = gettext('view-more-%(title)s', {
        title: title?.toLowerCase(),
    });

    return (
        <div
            className="filterSection"
            style={tokens as React.CSSProperties}
            data-testid={`filter-section__${title?.toString()?.toLowerCase()}`}
            ref={node}
        >
            <ReadMore
                ariaLabel={readMoreA11yLabel.toString()}
                initialIsExpanded={shouldScrollIntoView}
                previewChildInitializer={() =>
                    childClampState(
                        PREVIEW_CHILD_COUNT,
                        radioValueElements.length,
                    )
                }
            >
                <ChoiceListBase
                    name={`${title}_${isMobile ? 'mobile' : 'desktop'}`}
                    size={'small'}
                    selected={activeFilterOption || defaultActiveFilterOption}
                    onChange={(val: string) => handleOptionChange(val)}
                    title={title}
                    titleHidden={false}
                >
                    <ul
                        className="filter-choice-items"
                        id={readMoreA11yLabel.toString()}
                    >
                        <ReadMoreContent
                            previewChildCount={PREVIEW_CHILD_COUNT}
                            previewContentType="children"
                        >
                            {radioValueElements}
                        </ReadMoreContent>
                    </ul>
                </ChoiceListBase>
                <ReadMoreToggle toggleClass="filter-toggle" />
            </ReadMore>
        </div>
    );
};

export default FilterSection;
