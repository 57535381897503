import React from 'react';
import { ReadMoreProvider } from './context';
import { ReadMoreProps } from './types';

export { ReadMoreContent } from './ReadMoreContent';
export { ReadMoreToggle } from './ReadMoreToggle';
export { childClampState } from './utils';

export const ReadMore: React.FunctionComponent<ReadMoreProps> = (props) => {
    return (
        <ReadMoreProvider
            isExpanded={props.initialIsExpanded}
            ariaLabel={props.ariaLabel}
            previewChildInitializer={props.previewChildInitializer}
        >
            {props.children}
        </ReadMoreProvider>
    );
};
