import { PRICE_MAPPING } from '@eventbrite/discover-utils';
import React from 'react';
import {
    FACETED_SEARCH_DROPDOWN_FILTER_PROP_MAP,
    PRICE_FILTER,
} from '../../../constants';
import {
    constructFilterItemKey,
    FilterItem,
    FilterProps,
    FilterSection,
    RadioType,
} from '../FilterPanel';

export const PriceFilters = (props: FilterProps) => {
    return (
        <FilterSection
            filters={{
                ...PRICE_MAPPING,
            }}
            onOptionChange={(value: any) => {
                props.onFilterChange?.(PRICE_FILTER, value);
            }}
            title={FACETED_SEARCH_DROPDOWN_FILTER_PROP_MAP[PRICE_FILTER].label}
            activeFilterOption={props.selectedFilters?.[PRICE_FILTER]}
            isMobile={props.isMobile}
            shouldScrollIntoView={props.filterTypeToShow === PRICE_FILTER}
        >
            {(filterItem: RadioType) => {
                return (
                    <FilterItem
                        key={constructFilterItemKey(
                            'price',
                            filterItem.value,
                            props.isMobile,
                        )}
                        value={filterItem.value}
                        display={filterItem.display}
                    />
                );
            }}
        </FilterSection>
    );
};
