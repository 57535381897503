import { Checkbox } from '@eventbrite/marmalade';
import React from 'react';
import {
    FACETED_SEARCH_DROPDOWN_FILTER_PROP_MAP,
    FORMATTED_LANGUAGES,
    LANGUAGE_FILTER,
} from '../../../constants';
import {
    constructFilterItemKey,
    FilterItem,
    FilterProps,
    FilterSection,
    RadioType,
} from '../FilterPanel';

export const LanguageFilters = (props: FilterProps) => {
    return (
        <FilterSection
            filters={{
                ...FORMATTED_LANGUAGES,
            }}
            onOptionChange={(value: any) => {
                props.onFilterChange?.(LANGUAGE_FILTER, value);
            }}
            title={
                FACETED_SEARCH_DROPDOWN_FILTER_PROP_MAP[LANGUAGE_FILTER].label
            }
            activeFilterOption={props.selectedFilters?.[LANGUAGE_FILTER]}
            shouldScrollIntoView={props.filterTypeToShow === LANGUAGE_FILTER}
            defaultActiveFilterOption={[]}
            isMobile={props.isMobile}
        >
            {(filterItem: RadioType) => {
                return (
                    <FilterItem
                        ChoiceComponent={Checkbox}
                        key={constructFilterItemKey(
                            'language',
                            filterItem.value,
                            props.isMobile,
                        )}
                        value={filterItem.value}
                        display={filterItem.display}
                    />
                );
            }}
        </FilterSection>
    );
};
