import React from 'react';
import './FilterSection.scss';

const SCROLL_ROOT = 'animated-drawer__content';
const HEADER_OFFSET_CLASS = 'marmalade-filter-panel-mobile-header';
const FILTER_PANEL_HEADER_OFFSET = 135;

export function useShouldScrollIntoView(shouldScrollIntoView: boolean) {
    const node = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        if (shouldScrollIntoView) {
            setTimeout(() => {
                const offset = document
                    .getElementsByClassName(HEADER_OFFSET_CLASS)[0]
                    .getBoundingClientRect().height;
                const scrollRoot =
                    document.getElementsByClassName(SCROLL_ROOT)[0];

                const rect = node?.current?.getBoundingClientRect();
                const top = rect?.top || FILTER_PANEL_HEADER_OFFSET;
                const scrollDelta = top - offset;

                requestAnimationFrame(() => {
                    scrollRoot.scrollBy({
                        top: scrollDelta,
                        behavior: 'smooth',
                    });
                });
            }, 500);
        }
    }, [shouldScrollIntoView]);

    return node;
}
